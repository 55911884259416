/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
// require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

require('jquery');
require('jquery-ui/jquery-ui');


/* The jQuery UI widget factory, can be omitted if jQuery UI is already included */
// require('imports?define=>false&exports=>false!blueimp-file-upload/js/vendor/jquery.ui.widget.js');
/* The Iframe Transport is required for browsers without support for XHR file uploads */
// require('imports?define=>false&exports=>false!blueimp-file-upload/js/jquery.iframe-transport.js');
/* The basic File Upload plugin */
// require('imports?define=>false&exports=>false!blueimp-file-upload/js/jquery.fileupload.js');
/* The File Upload processing plugin */
// require('imports?define=>false&exports=>false!blueimp-file-upload/js/jquery.fileupload-process.js');
/* The File Upload validation plugin */
// require('imports?define=>false&exports=>false!blueimp-file-upload/js/jquery.fileupload-validate.js');

require('blueimp-file-upload/js/jquery.iframe-transport');
require('blueimp-file-upload/js/jquery.fileupload');
require('blueimp-file-upload/js/jquery.fileupload-process');
require('blueimp-file-upload/js/jquery.fileupload-validate');

require('jquery.numeric/jquery.numeric');
require('sticky-table-headers');
require('jquery-word-and-character-counter-plugin');
require('select2/select2');
require('select2/select2_locale_bg');
require('bootstrap-sass/assets/javascripts/bootstrap');
require('bootstrap-datepicker');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.bg');
require('bootstrap-touchspin');
require('bootbox');
require('moment');
require('filesize');
